/* Google Fonts - Poppins */
.navbar-fixed-top .navbar-toggle .icon-bar {
  background-color: #fff;
}

.navbar>.container .navbar-brand{
  margin-left: 0;
}

.navbar-brand {
  padding: 0;
  margin:0;
}

.navbar-header a.navbar-brand {
   color:#111;
   font-family:'Roboto';
}

.navbar-header a.navbar-brand:hover{
   color:#fff;
}

.navbar {
  border-radius: 0;
  margin-bottom: 0;
  background: #151515;
  padding: 21px 0;
  padding-bottom: 0;
}

.navbar-nav{
  margin-top: 12px;
 }

.navbar-nav>li{
  margin-left: 20px;
  padding-bottom: 28px;
}

.navbar-fixed-top .navbar-nav > li > a {
	padding: 5px 12px;
	margin: 0;
	border-radius: 3px;
	color: #111;
	line-height: 24px;
	display: inline-block;
	font-weight:500;
	font-size:18px;
	font-family:'Roboto';
}

.navbar-fixed-top .navbar-nav > li > a:hover{
	background: #0BA9F9;	
	color:#fff;
}

.navbar-fixed-top {
  border: none;
  background: #0BA9F9;
}

.navbar-fixed-top .navbar-brand {
  font-size: 36px;
  line-height: 50px;
  color: #fff;
}

.navbar-fixed-top .navbar-nav > .active > a,
.navbar-fixed-top .navbar-nav > .active > a:hover,
.navbar-fixed-top .navbar-nav > .active > a:focus,
.navbar-fixed-top .navbar-nav > .open > a,
.navbar-fixed-top .navbar-nav > .open > a:hover,
.navbar-fixed-top .navbar-nav > .open > a:focus {
  background-color: #111;
  color: #fff;
}

#our-team {
	padding-top:70px;
	padding-bottom:70px;
	background:#f4f4f4;
}

#our-team h4 {
	color:#0BA9F9;
}

#our-team .text-center {
	padding-top:20px;
	margin-bottom:30px;
	padding-bottom:10px;
}
#our-team .row {
	justify-content: center;
}

#our-team .team img {
	border-radius:8px;
}
.team-container1{
  max-width: 1120px;
  width: 100%;
  padding: 40px;  
}

.team-content11{
  margin: 0 40px;
  flex-direction: row;
  display: flex;
  align-items: center;
  justify-content: center;
  
}
.team_title11{
  font-weight: bold;
  color: #020024;
  font-size: 30px; 
  justify-content: center;
}
.team_title111{
 
  font-size: 20px; 
  justify-content: center;
}
.team_title22{
  font-size: 20px; 
  
 
}
.swiper-navBtn{
  color: #6E93f7;
  transition: color 0.3s ease;
}
.team_name{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
 
  }
  
  .img{
    width: 80%;
    height: 500px;
    object-fit: contain;
  }
  
  .team_overlay1{
    left: 0;
  top: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.26);
    display: flex;
    padding: 40px 30px;
    align-items: center;
    flex-direction: column-reverse;
  }
  .team_card_title{
    font-weight: bold;
    color: #fff;
    font-size: 25px; 
    justify-content: center;
  }
@media screen and (max-width: 768px) {
  .img{
    width: 40%;
    height: 40%;
    object-fit: contain;
  }
  .team_title11{
    font-weight: bold;
    color: #020024;
    font-size: 20px; 
    justify-content: center;
  }
  .team_title111{
    font-size: 16px; 
    justify-content: center;
  }

.team-content1{
  margin: 0 40px;
  flex-direction: row;
  display: flex;
  align-items: center;
  justify-content: center;
  
}
  
}
