#logo {
    height: 10px;
}
.logo {
    top: 0;
    height: 10px;  
    display: flex;
    flex-direction: row; 
    margin-left: 20px;

}
.logo_image {
    height: 48px ;
    width: 48px ;  
  }

.teste {
    padding-left: 100pxj
}

.easy1 {
    margin-left: 5px;
    color: #fff;
    font-size: 20px;
    margin-top: 20px;
}
.f_image {
    height: 10% ;
    width: 10% ;  
  }
.vog1 {
    color: #fff;
    font-weight: bolder;
    font-size: 20px;
}

.nav{
    width: 100%;
    height: 65px;
    position: fixed;
    line-height: 65px;
    text-align: center;
    position: fixed;
    z-index: 2;
}
#logoSection{
  
    height: 10px !important;
    min-height:10px;
    /* margin-top: -70px */
   
}
.nav div.logo {
    float: left;
    width: auto;
    height: auto;
    padding-left: 3rem;
}

.nav div.logo a {
    text-decoration: none;
    color: #fff;
}

.nav div.logo a:hover {
    color: #00E676;
}

.nav div.main_list {
    height: 65px;
    float: right;
}

.nav div.main_list ul {
    width: 100%;
    height: 65px;
    display: flex;
    list-style: none;
    margin: 0;
    padding: 0;
}

.nav div.main_list ul li {
    width: auto;
    height: 65px;
    padding: 0;
    padding-right: 3rem;
}

.navTrigger {
    display: none;
}
.nav {
    padding-top: 20px;
    padding-bottom: 20px;
    -webkit-transition: all 0.4s ease;
    transition: all 0.4s ease;
    background-color: #020024;
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
    .container {
        margin: 0;
    }
}

@media screen and (max-width:768px) {
    .logo_image {
        height: 32px ;
        width: 32px ;  
      }
      .vog1 {
        color: #fff;
        font-weight: bolder;
        font-size: 15px;
       
    }
    .easy1 {
        margin-left: 10px;
        color: #fff;
        font-size: 20px;
        margin-top:10px;
    }
}
@media screen and (max-width:600px) {
    .nav{
       
        width: 100%;
        height: 65px;
    }
    .easy {
        margin-left: 10px;
        color: #000;
        font-size: 20px;
    }
    .f_image {
        height: 10% ;
        width: 10% ;  
      }
    .vog {
        color: #000;
        font-weight: bolder;
        font-size: 20px;
    }
}

.affix {
    padding: 0;
    background-color: #020024;
}

.bi-person,
.bi-bell,
.bi-box-arrow-right {
    color: #020024;
}

/* .linkLogin {
    color: #222533;
} */