/* Google Fonts - Poppins */


.slide-container11{
  width: 100%;
  padding: 40px;  
}
.slide-content11{
  width: 100%;
  padding: 10px;
  overflow: hidden;
  border-radius: 25px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.card11{
  width: 10%;
}
.image-content1,
.card-content11{
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px 14px;
}
.image-content1{
  position: relative;
  row-gap: 5px;
  padding: 25px 0;
}
.overlay1{
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background-color: #4070F4;
  border-radius: 25px 25px 0 25px;
}
.overlay1::before,
.overlay1::after{
  content: '';
  position: absolute;
  right: 0;
  bottom: -40px;
  height: 40px;
  width: 40px;
  background-color: #4070F4;
}
.overlay1::after{
  border-radius: 0 25px 0 0;
  background-color: #FFF;
}
.card-image1{
  position: relative;
  height: 150px;
  width: 150px;
  border-radius: 50%;
  background: #FFF;
  padding: 3px;
}
.card-image1 .card-img1{
  height: 100%;
  width: 100%;
  object-fit: contain;
  border-radius: 50%;
  border: 4px solid #4070F4;
}
.name{
  font-size: 18px;
  font-weight: 500;
  color: #333;
}
.description{
  font-size: 14px;
  color: #707070;
  text-align: center;
}
.button{
  border: none;
  font-size: 16px;
  color: #FFF;
  padding: 8px 16px;
  background-color: #4070F4;
  border-radius: 6px;
  margin: 14px;
  cursor: pointer;
  transition: all 0.3s ease;
}
.project_title1{
  font-weight: bold;
  padding-top: 30px;
  color: #020024;
  font-size: 40px;
}

@media screen and (max-width: 768px) {
  .project_title1{
    font-weight: bold;
    padding-top: 30px;
    color: #020024;
    font-size: 20px;
  }
  .slide-container11{
  
    max-width: 100%;
    padding: 0px;  
  }
  .slide-content11{
    margin: 0px;
    overflow: hidden;
    border-radius: 25px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .card1{
    border-radius: 2px;
    background-color: #FFF;
  }
  .image-content1,
  .card-content11{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px 14px;
  }
  .image-content1{
    position: relative;
    row-gap: 5px;
    padding: 20px 0;
  }
  .overlay1{
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    background-color: #4070F4;
    border-radius: 25px 25px 0 25px;
  }
  .overlay1::before,
  .overlay1::after{
    content: '';
    position: absolute;
    right: 0;
    bottom: -40px;
    height: 40px;
    width: 40px;
    background-color: #4070F4;
  }
  .overlay1::after{
    border-radius: 0 25px 0 0;
    background-color: #FFF;
  }
  .card-image1{
    position: relative;
    height: 75px;
    width: 75px;
    border-radius: 50%;
    background: #FFF;
    padding: 3px;
  }
  .card-image1 .card-img1{
    height: 100%;
    width: 100%;
    object-fit: contain;
    border-radius: 50%;
    border: 4px solid #4070F4;
  }
  .name1{
    font-size: 15px;
    font-weight: 500;
    color: #333;
  }
  .description1{
    font-size: 12px;
    color: #707070;
    text-align: center;
  }
  .button{
    border: none;
    font-size: 16px;
    color: #FFF;
    padding: 8px 16px;
    background-color: #4070F4;
    border-radius: 6px;
    margin: 14px;
    cursor: pointer;
    transition: all 0.3s ease;
  }
  .swiper-navBtn{
    display: none;
  }
}