/* .home {
    height: 120vw;
} */
.icon {
    font-size: 30px;
    color: #fff;
}

.Header{
    background-color: #020024;
    height: 400px !important;
}

.carousel{
  background-color: #020024;
}

#logoSection{
    height: 200px !important;
    /* margin-top: -70px */
    margin-top: 0px !important;
}

.height {
    height: 100px;
    background-color: #020024;
}



/*sidebar*/


.unorderedList {
  background: #222;
  padding: 40px 0 10px 10px;
  border-right: 5px solid #DAA520;
  width: 150px;
  transition: 0.5s;
  overflow: hidden;
  position:fixed;
  left: 0;
  height: 100%;
  z-index: 3;
}

.unorderedList li {
  list-style: none;
  padding: 15px;
  padding-right: 0;
  color: white;
  font-size: 15px;
  margin-bottom: 10px;
  cursor: pointer;
  position: relative;
  transition: 0.5s;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;

}

a {
    color: #fff
}
a:hover{
    color:#fff;
    text-decoration: none;
}

.unorderedList li:hover {
  transition: 0s;
  background: #242932;
}

.unorderedList li.active:before {
  content: '';
  position: absolute;
  top:-20px;
  right: 0px;
  width: 20px;
  height:20px;
  background: transparent;
  border-radius: 50%;
  box-shadow: 10px 10px 0 #DAA520;
}

.unorderedList li.active:after {
  content: '';
  position: absolute;
  bottom:-20px;
  right: 0px;
  width: 20px;
  height:20px;
  background: transparent;
  border-radius: 50%;
  box-shadow: 10px -10px 0 #DAA520;

}

.unorderedList li.active {
  background: #005A98;
}

.unorderedList:hover {
  width: 220px
}

.logoHeader{
    background-color: #222;
    height: 200px;
    margin-top: 100px;
}
.sectionProcessAcess{
    background-color: #020024;
    height: auto;
    margin-top: -40px;
    padding: 30px;
}



.textBold {
  font-weight: bold;
  border-bottom: solid 2px #005A98;
}


.about {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  
}


.about_title1 {
  font-weight: bold;
  padding-top: 30px;
  color: #020024;
  font-size: 40px; 
}

.about_title2 {
  width: 50%;
  color: #000;
  font-size: 20px;
  
}
.project_title {
  font-weight: bold;
  padding-top: 30px;
  color: #020024;
  font-size: 30px; 
  justify-content: center;
}
.project_name {
display: flex;
  justify-content: center;
}
.slide-container{
  max-width: 100%;
  width: 100%;
  padding: 40px 0;
 justify-content: center;
  
}
.slide-content{
  margin: 0 40px;
  border-radius: 25px;
  flex-direction: row;
  display: flex;
  align-items: center;
  justify-content: center;
  
  
}

.card{
  border-radius: 25px;
  background-color: #FFF;
  width: 25%;
  margin-left: 20px;

}
.card2{
  border-radius: 25px;
  background-color: #FFF;
  width: 25%;
  margin-left: 20px;

}

.image-content,
.card-content{
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px 14px;
}
.image-content{
  position: relative;
  row-gap: 5px;
  padding: 25px 0;
}
.overlay{
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background-color: #005A98;
  border-radius: 25px 25px 0 25px;
}
.overlay::before,
.overlay::after{
  content: '';
  position: absolute;
  right: 0;
  bottom: -40px;
  height: 40px;
  width: 40px;
  background-color: #005A98;
}
.overlay::after{
  border-radius: 0 25px 0 0;
  background-color: #FFF;
}
.card-image{
  position: relative;
  height: 150px;
  width: 150px;
  border-radius: 50%;
  background: #FFF;
  padding: 3px;
}
.card-image .card-img{
  height: 100%;
  width: 100%;
  object-fit: contain;
  border-radius: 50%;
  border: 4px solid #005A98;
}
.name{
  font-size: 18px;
  font-weight: 500;
  color: #333;
}
.description{
  font-size: 14px;
  color: #707070;
  text-align: center;
}

.team-container{
  max-width: 100%;
  width: 100%;
  padding: 40px 0;
 justify-content: center;
  
}
.team-content{
  margin: 0 40px;
  border-radius: 25px;
  flex-direction: row;
  display: flex;
  align-items: center;
  justify-content: center;
  
  
}
.service_title{
  font-weight: bold;
  color: #020024;
  font-size: 20px; 
  justify-content: center;
}
.team_title2{
  font-size: 20px; 
  justify-content: center;
  align-items: center;
  width: 50%;
}
.f_image1{
  height: 50% !important;
  width: 50% !important;
  object-fit: cover;  
}

.swiper-navBtn{
  color: #6E93f7;
  transition: color 0.3s ease;
}
.team_name{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
 
  }
  .card1 {
    border-radius: 25px;
    background-color: #FFF;
    width: 25%;
    margin-left: 20px;
    
  }
  .team_image {
    width: 100%;
    height: 500px;
    object-fit: cover;
  }
  
  .team_overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.26);
    display: flex;
    padding: 40px 30px;
    align-items: center;
    flex-direction: column-reverse;
  }
  .team_card_title{
    font-weight: bold;
    color: #fff;
    font-size: 25px; 
    justify-content: center;
  }
  .team_h{
   
    color: #fff;
    
  }
  .last-container{
    max-width: 100%;
    width: 100%;
    height: 30%;
    display: flex;
    padding: 40px 0;
   justify-content: center;
   
  }
  .last-content{
    width: 100%;
    height: 100%;
  }
  .last_image {
    width: 100%;
    height: 100%;
    object-fit: cover;
   
  }
  .footer_logo {
    display: flex;
    flex-direction: row;
   
  }
  .service_content {
    display: flex;
    flex-direction: row;
    margin-left: 100px;
   
  }
  .footer_nname{
    padding-left: 10px;
  }
  .last_overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.26);
    display: flex;
    padding: 40px 30px;
    align-items: center;
   
  }
  .service_card{
   
   
    width: 50%;
  }
  .footer_left1{
    font-weight: bold;
    color: #FFF;
    font-size: 20px; 
    justify-content: center;
  }
.swiper-navBtn:hover{
  color: #4070F4;
}
.swiper-navBtn::before,
.swiper-navBtn::after{
  font-size: 35px;
}
.swiper-button-next{
  right: 0;
}
.swiper-button-prev{
  left: 0;
}
.swiper-pagination-bullet{
  background-color: #6E93f7;
  opacity: 1;
}
.swiper-pagination-bullet-active{
  background-color: #4070F4;
}





/*button*/
a {
  color: #f4f4f4;
}




/*footer*/
.footer {
    background-color: #222;  
    padding: 50px;
    height: auto;
}

.fa-facebook-f, .fa-instagram, .fa-linkedin-in, .fa-phone,.fa-envelope-open, .fa-map-marker-alt, .fa-comment{
  color: #fff;
  font-size: 25px;
}

.mTop {
  margin-top: 20px;
}

.footer2{
  background-color: #020024;
  padding-top: 5px;
  padding-bottom: 5px;
}
.text-footer22{
  color:#fff;
  font-size: 12px;
}
.footer_left11{
  font-weight: bold;
  color: #FFF;
  font-size: 20px; 
  justify-content: center;
  
}
@media screen and (max-width: 768px) {
  .footer_left11{
    width: 50%;
    font-weight: bold;
    color: #FFF;
    font-size: 14px; 
    justify-content: center;
    
  }
  .text-footer22{
    color:#fff;
    font-size: 10px;
  }
  .f_image1 {
    height: 30% !important;
    width: 30% !important;
    object-fit: cover;  
  }
  .footer_left1{
    font-weight: bold;
    color: #FFF;
    font-size: 14px; 
    justify-content: center;
    
  }
  .footer_left11{
    width: 50%;
    font-weight: bold;
    color: #FFF;
    font-size: 14px; 
    justify-content: center;
    
  }
  .service_content1 {
    display: flex;
    flex-direction: row;
    margin-left: 50px;
   
   
  }
  .service_title1{
    font-weight: bold;
    color: #020024;
    font-size: 12px; 
    justify-content: center;
  }
  .team_title11{
    font-weight: bold;
    color: #020024;
    font-size: 20px; 
    justify-content: center;
  }
  .team_title22{
    font-size: 16px; 
    justify-content: center;
    align-items: center;
    width: 70%;
  }
.team-container1{
  width: 100%;
  padding: 40px;  
  align-items: center;
  justify-content: center;
}
.team-content1{
  margin: 0 -400px 0 -400px;
  border-radius: 25px;
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;
  
}
#logoSection1{
  height: 50px !important;
  /* margin-top: -70px */
  
}
  
}



