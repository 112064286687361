/* Google Fonts - Poppins */


.clients1{
  width: 100%;
  padding: 40px;  
}
.client1{
  display: flex;
 align-items: center;
 justify-content: center;
 flex-direction: row;  
}
.team_title11{
  font-weight: bold;
  color: #020024;
  font-size: 40px; 
  justify-content: center;
}

@media screen and (max-width: 768px) {
  .team_title11{
    font-weight: bold;
    color: #020024;
    font-size: 20px; 
    justify-content: center;
  }  

.clients1{
  width: 100%;
  padding: 40px;  
}
.client1{
 display: flex;
 align-items: center;
 justify-content: center;
 flex-direction: column;
}
  
}