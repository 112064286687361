.nav div.main_list ul li a {
    text-decoration: none;
    /* color: #fff; */
    line-height: 65px;
    font-weight: 500;
}
.nav div.main_list ul li a:hover {
    color: #DAA520;
}
.fa-user{
    color: #222533;
    font-size: 20px;
    margin-right: 20px;
}

.btnLogin{
    color: #222533;
}
@media screen and (max-width:768px) {
    .btnLogin{
        color: #222533;
        display:flex;
        flex-direction: row;
    }
    .nav div.main_list ul li a {
        text-decoration: none;
        /* color: #fff; */
        line-height: 6p5x;
        font-weight: 500;
        font-size: 14px;
    }
}
