body{
	margin: 0;
	padding: 0;
}
.Navbar{
	background-color: #020024;
	width: 100%;
	height: 100px;
	display: flex;
	font-family: Arial, Helvetica, sans-serif;
}

.hidden{
	background-color: #020024;
	width: 100%;
	height: 100px;
	display: flex;
	font-family: Arial, Helvetica, sans-serif;
}

button:hover{
	cursor: pointer;
}
.Navbar .leftSide {
	flex:50%;
	display: flex;
	justify-content:left;
	align-items:center;
	max-height: 50px;
}

.Navbar .rightSide .links {
	max-height: 100px;
	margin-right: 40px;
	
}
.Navbar .rightSide button {
	display: none;
	color: white;
}
.Navbar .rightSide #hidden {
	display: flex;
}
.Navbar .rightSide .links a {
	text-decoration: none;
	color:white;
	font-size: 18px;
	margin-left: 15px;
	
}

.Navbar .rightSide {
	flex:50%;
	display: flex;
	justify-content: flex-end;
	align-items:center;
	padding-right: 25px;
}


.hidden .leftSide {
	flex:50%;
	display: flex;
	justify-content:left;
	align-items:center;
	max-height: 50px;
}

.hidden .rightSide .links {
	max-height: 100px;
	margin-right: 40px;
	
}
.hidden .rightSide button {
	display: none;
	color: white;
}
.hidden .rightSide #hidden {
	display: flex;
}
.hidden .rightSide .links a {
	text-decoration: none;
	color:white;
	font-size: 18px;
	margin-left: 15px;
	
}

.hidden .rightSide {
	flex:50%;
	display: flex;
	justify-content: flex-end;
	align-items:center;
	padding-right: 25px;
}




@media only screen and (max-width: 850px){
	.Navbar .rightSide .links a {
	
		font-size: 15px;
		
	}
}
@media screen and (max-width:768px) {
	.hidden{
	
		height: 220px;
		display: flex;
		
	}
	.Navbar .rightSide button {
		display: flex;
		height:48px;
		width:70px;
	} 
	.Navbar .rightSide .links{
		display: none;
	}
	.Navbar .rightSide #hidden{
		position: absolute;
		left: 0;
		top: 80px;
		height: 00px;
		max-height:230px;
		width: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;

	}
	.Navbar .rightSide #hidden a{
	margin:10px;	
	font-size: 18px;
	}
	.Navbar .rightSide {
		flex:50%;
		display: flex;
		justify-content: flex-end;
		align-items:center;
		padding-right: 25px;
	}


	.hidden #hidden{
	
		height: 220px;
		display: flex;
		
	}
	.hidden .rightSide button {
		display: flex;
		height:48px;
		width:70px;
	} 
	.hidden .rightSide .links{
		display: none;
	}
	.hidden .rightSide #hidden{
		position: absolute;
		left: 0;
		top: 80px;
		height: 00px;
		max-height:230px;
		width: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;

	}
	.hidden .rightSide #hidden a{
	margin:10px;	
	font-size: 18px;
	}
	.hidden .rightSide {
		flex:50%;
		display: flex;
		justify-content: flex-end;
		align-items:center;
		padding-right: 25px;
	}
}
