/* Google Fonts - Poppins */
#logoSection1{
  height: 150px !important;
  /* margin-top: -70px */
  
}

.team-container1{
  width: 100%;
  padding: 40px;  
}

.team-content1{
  margin: 0 40px;
  border-radius: 25px;
  flex-direction: row;
  display: flex;
  align-items: center;
  justify-content: center;
  
}
.team_title11{
  font-weight: bold;
  color: #020024;
  font-size: 30px; 
  justify-content: center;
}
.team_title22{
  font-size: 20px; 
  justify-content: center;
  align-items: center;
  width: 50%;
}
.swiper-navBtn{
  color: #6E93f7;
  transition: color 0.3s ease;
}
.team_name{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
 
  }
  .card1{
    border-radius: 25px;
    background-color: #000;
    width: 2%;
    margin-left: 20px;
    
  }
  .team_image11 {
    width: 100%;
    height: 500px;
    object-fit: cover;
  }
  
  .team_overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.26);
    display: flex;
    padding: 40px 30px;
    align-items: center;
    flex-direction: column-reverse;
  }
  .team_card_title{
    font-weight: bold;
    color: #fff;
    font-size: 25px; 
    justify-content: center;
  }

  
.about {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  
}


.about_title1 {
  font-weight: bold;
  padding-top: 30px;
  color: #020024;
  font-size: 40px; 
}

.about_title2 {
  width: 50%;
  color: #000;
  font-size: 20px;
  
}
.project_title {
  font-weight: bold;
  padding-top: 30px;
  color: #020024;
  font-size: 30px; 
  justify-content: center;
}
.project_name {
display: flex;
  justify-content: center;
}
.slide-container{
  max-width: 100%;
  width: 100%;
  padding: 40px 0;
 justify-content: center;
  
}

.card-image{
  position: relative;
  height: 150px;
  width: 150px;
  border-radius: 50%;
  background: #FFF;
  padding: 3px;
}
.card-image .card-img{
  height: 100%;
  width: 100%;
  object-fit: contain;
  border-radius: 50%;
  border: 4px solid #005A98;
}
.name{
  font-size: 18px;
  font-weight: 500;
  color: #333;
}
.description{
  font-size: 14px;
  color: #707070;
  text-align: center;
}

.team-container{
  max-width: 100%;
  width: 100%;
  padding: 40px 0;
 justify-content: center;
  
}
.team-content{
  margin: 0 40px;
  border-radius: 25px;
  flex-direction: row;
  display: flex;
  align-items: center;
  justify-content: center;
  
  
}
.service_title1{
  font-weight: bold;
  color: #020024;
  font-size: 20px; 
  justify-content: center;
}
.team_title2{
  font-size: 20px; 
  justify-content: center;
  align-items: center;
  width: 50%;
}
.f_image {
  height: 50% !important;
  width: 50% !important;
  object-fit: cover;  
}

.swiper-navBtn{
  color: #6E93f7;
  transition: color 0.3s ease;
}
.team_name{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
 
  }
  .card1 {
    border-radius: 25px;
    background-color: #FFF;
    width: 25%;
    margin-left: 20px;
    
  }
  .team_image {
    width: 100%;
    height: 500px;
    object-fit: cover;
  }
  
  .team_overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.26);
    display: flex;
    padding: 40px 30px;
    align-items: center;
    flex-direction: column-reverse;
  }
  .team_card_title{
    font-weight: bold;
    color: #fff;
    font-size: 25px; 
    justify-content: center;
  }
  .team_h{
   
    color: #fff;
    
  }
  
  
  .service_content1 {
    display: flex;
    flex-direction: row;
    margin-left: 100px;
   
  }
  .services_content1{
    display: flex;
    
    justify-content: center;
   
   
  }
@media screen and (max-width: 768px) {
  .text-footer22{
    color:#fff;
    font-size: 10px;
  }
  .f_image1 {
    height: 30% !important;
    width: 30% !important;
    object-fit: cover;  
  }
  .footer_left1{
    font-weight: bold;
    color: #FFF;
    font-size: 14px; 
    justify-content: center;
    
  }
  .footer_left11{
    width: 50%;
    font-weight: bold;
    color: #FFF;
    font-size: 14px; 
    justify-content: center;
    
  }
  .service_content1 {
    display: flex;
    flex-direction: row;
    margin-left: 50px;
   
   
  }
  .service_title1{
    font-weight: bold;
    color: #020024;
    font-size: 12px; 
    justify-content: center;
  }
  .team_title11{
    font-weight: bold;
    color: #020024;
    font-size: 20px; 
    justify-content: center;
  }
  .team_title22{
    font-size: 16px; 
    justify-content: center;
    align-items: center;
    width: 70%;
  }
.team-container1{
  width: 100%;
  padding: 40px;  
  align-items: center;
  justify-content: center;
}
.team-content1{
  margin: 0 -400px 0 -400px;
  border-radius: 25px;
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;
  
}
#logoSection1{
  height: 50px !important;
  /* margin-top: -70px */
  
}
  
}