@import url('https://cdnjs.cloudflare.com/ajax/libs/animate.css/4.1.1/animate.min.css');

#buttonInitial{
  display: none;
}

.App {
    background-color: #222;
    overflow: hidden !important;
    height: 50.5vw;
}
.App-logo{
    height: 100%;
    max-height: 180px;
}

#easy{
    color:#daa520;
    text-transform: uppercase;
}


/*typing animation*/
.wrapper{
  display: none;
}
.wrapper .static-txt{
  color: #fff;
  font-size: 60px;
  font-weight: 400;
}
.wrapper .dynamic-txts{
  margin-left: 130px;
  height: 90px;
  line-height: 90px;
  overflow: hidden;
}
.dynamic-txts li{
  list-style: none;
  color: #fff;
  font-size: 45px;
  font-weight: 500;
  position: relative;
  top: 0;
  animation: slide 12s steps(4) ;
}
@keyframes slide {
  100%{
    top: -360px;
  }
}
.dynamic-txts li p{
  position: relative;
  margin: 5px 0;
  line-height: 90px;
}
.dynamic-txts li p::after{
  content: "";
  position: absolute;
  left: 0;
  height: 110%;
  width: 100%;
  background: #222;
  border-left: 2px solid #daa520;
  animation: typing 3s steps(10) infinite;
}
@keyframes typing {
  40%, 60%{
    left: calc(100% + 30px);
  }
  100%{
    left: calc(100% + 30px);
  }
}


/*svg animation*/
svg {
  display: block;
  margin: auto;
}

path {
  stroke: #fff;
  stroke-width: 2px;
  stroke-dasharray: 853;
  animation: 4s draw forwards;
}

@keyframes draw {
  0% {
    fill-opacity: 0;
    stroke-dashoffset: 853;
  }
  70% {
    fill-opacity: 0;
    stroke: #fff;
  }
  100% {
    fill-opacity: 1;
    stroke-dashoffset: 0;
    stroke: none;
  }
}


#load{
  display:none
}
/*slide*/
#slideLeft {
    position: absolute;
    right: 580px;
    -webkit-animation: slide 1.5s forwards;
    -webkit-animation-delay: 0.01s;
    animation: slide 1.5s forwards;
    animation-delay: 0.01s;
}

@-webkit-keyframes slide {
    100% { right: 1000px; }
}

@keyframes slide {
    100% { right: 1000px; }
}

/*slide*/
